<script setup lang="ts">
const { content } = defineProps<{
  content: {
    config: {
      elements: {
        value: Array<{
          image: string | null;
          heading: string;
          text: string;
          link: string;
        }>;
      };
      headingColor: {
        value: string;
      };
      textColor: {
        value: string;
      };
    };
  };
}>();

const elements = content.config.elements.value;
const headingColor = content.config.headingColor?.value ?? "#006496";
const textColor = content.config.textColor?.value ?? "#000000";
</script>

<template>
  <div class="grid gap-8 sm:grid-cols-2 xl:grid-cols-4 max-sm:gap-12">
    <template v-for="(element, index) in elements" :key="index">
      <NuxtLink
        v-if="element.link"
        :to="element.link"
        class="flex gap-4 items-center items-start max-sm:flex-col"
      >
        <SharedMediaImage
          :media="element.image"
          class="size-24 flex-shrink-0"
        />
        <div
          class="max-sm:text-center mt-0 flex h-full flex-col gap-2"
          :class="{ 'justify-center': element.text == '' }"
        >
          <h3 :style="{ color: headingColor }">
            {{ element.heading }}
          </h3>
          <div :style="{ color: textColor }">
            {{ element.text }}
          </div>
        </div>
      </NuxtLink>
      <div v-else class="flex gap-4 items-center items-start max-sm:flex-col">
        <SharedMediaImage
          :media="element.image"
          class="size-24 flex-shrink-0"
        />
        <div
          class="max-sm:text-center mt-0 flex h-full flex-col gap-2"
          :class="{ 'justify-center': element.text == '' }"
        >
          <h3 :style="{ color: headingColor }">
            {{ element.heading }}
          </h3>
          <div :style="{ color: textColor }">
            {{ element.text }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
